import React, { useState } from 'react'
import Sidebar from '../components/Sidebar'
import { Outlet } from 'react-router-dom'
import Button from '../components/Button';
import { BsPlusLg } from "react-icons/bs";
import indiaIcon from '../assets/indiaIcon.png';
import usaIcon from '../assets/usaIcon.png';
import { CgArrowLongRight } from "react-icons/cg";
import Dropdown from '../components/Dropdown';
import { IoMdClose } from "react-icons/io";
import SearchBar from '../components/SearchBar'
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { LuBellRing } from "react-icons/lu";



const Dashboard = () => {
    const isLoggedIn = localStorage.getItem("isLoggedIn")
    const loggedInUser = localStorage.getItem("loggedInUser")

    const [newQuote, setNewQuote] = useState(false);
    const funNewQuote = () => {
        setNewQuote(!newQuote);
    }


    const freightOptions = [
        'Ocean Freight (FCL)',
        'Ocean Freight (LCL)',
        'Air Freight'
    ];
    const [selectedFreight, setSelectedFreight] = useState('');
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const handleSelect = (option) => {
        setSelectedFreight(option);
        setIsDropdownOpen(false); // Close dropdown after selection
    };

    const handleToggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const [isComponentVisible, setIsComponentVisible] = useState(true);
    const handleClose = () => {
        // setSelectedFreight('');
        // setIsDropdownOpen(false); // Ensure dropdown is closed when reset
        setNewQuote(!newQuote); // Close the entire component
    };



    return (
        <>
            {
                !isLoggedIn ? window.location = "/login" : loggedInUser === "transporter" ? window.location = "/transporter/quotation-request" : ""
            }
            <div className='w-full h-screen flex items-stretch'>
                <Sidebar />

                <div className='flex flex-col w-full '>
                    <div className='w-full relative border-b shadow flex items-center justify-end px-5 flex flex-row gap-3'>
                        <SearchBar />

                        <RouterLink to="/notification">
                            <Button bgColor="px-[15px] py-2 lg:py-3  border-[1px] border-btnbBorder rounded-[5px] text-[14px] text-lgntxt font-medium"><LuBellRing size={18} /></Button>
                        </RouterLink>
                    </div>


                    <div className='px-8 py-12 overflow-auto'>
                        <Outlet />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Dashboard