import React, { useState, useEffect, useContext } from 'react'
import Sidebar from '../components/Sidebar'
import { Outlet } from 'react-router-dom'
import Button from '../components/Button';
import { BsPlusLg } from "react-icons/bs";
import indiaIcon from '../assets/indiaIcon.png';
import usaIcon from '../assets/usaIcon.png';
import { CgArrowLongRight } from "react-icons/cg";
import Dropdown from '../components/Dropdown';
import { IoMdClose } from "react-icons/io";
import { MdVerified } from "react-icons/md";
import nikeIcon from '../assets/nike.png'
import profileIcon from '../assets/uploadLogo.png'
import { MdOutlinePhone } from "react-icons/md";
import { TbMail } from "react-icons/tb";
import { IoEarthOutline } from "react-icons/io5";
import { FaStar } from "react-icons/fa";
import axios from 'axios';
import { Circles } from 'react-loader-spinner';
import { MdLocationPin } from "react-icons/md";
import { FaNetworkWired } from "react-icons/fa";
import { CgProfile } from "react-icons/cg";
import SearchContext from '../SearchContext';



const Directory = () => {

    const [directoryData, setDirectoryData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [filteredData, setFilteredData] = useState([]);
    const { searchQuery } = useContext(SearchContext);
    const [data, setData] = useState([]);



    const userData = JSON.parse(localStorage.getItem('userData'))


    const getDashboardQuotationrequest = () => {

        axios.get(`https://api.freightopedia.world/api/v1/logistics/directory?user_id=${userData !== null ? userData.id : ""}`, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem("authToken")}`
            }
        })
            .then(response => {
                setLoading(false);
                // handle success



                if (response.data.success) {
                    setDirectoryData(response.data.data)


                }
            })
            .catch(error => {
                // handle error

            });
    }



    useEffect(() => {
        setLoading(true);
        getDashboardQuotationrequest();
        fetchData();
    }, []);


    useEffect(() => {
        if (searchQuery) {
            const lowercasedQuery = searchQuery.toLowerCase();
            const filtered = data.filter(item =>
                Object.values(item).some(
                    value =>
                        value && typeof value === 'string' && value.toLowerCase().includes(lowercasedQuery)
                )
            );
            setFilteredData(filtered);
        } else {
            setFilteredData([]); // Clear filtered data if searchQuery is empty
        }
    }, [searchQuery, data]);


    const fetchData = () => {
        axios.get(`https://api.freightopedia.world/api/v1/logistics/directory?user_id=${userData !== null ? userData.id : ""}`, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem("authToken")}`
            }
        })
            .then(response => {
                setLoading(false);
                // handle success



                if (response.data.success) {
                    if (response.data.success) {
                        setData(response.data.data);
                        // setFilteredData(response.data.data);
                    }
                }
            })
            .catch(error => {
                // handle error

            });
    };



    return (
        <>

            <div className="grid max-md:grid-cols-1 md:grid-cols-1 md2:grid-cols-2 max-lg:grid-cols-1 lg:grid-cols-2 max-xl:grid-cols-2 xl:grid-cols-3 gap-y-8 gap-x-3 mt-3 w-full">


                {!loading && searchQuery && filteredData.length === 0 && (
                    <p>No results found</p>
                )}

                {!loading && (searchQuery ? filteredData : directoryData).map((item, index) => (
                    <div key={index} className="flex flex-col gap-3 border-[1px] border-[#E0E0E0] pt-5 pb-1 px-5 w-auto">
                        <div className="h-1/3 flex flex-col gap-3">
                            {item.is_verified && (
                                <h1 className="text-[#49B5FD] border-[1px] border-[#E0E0E0] py-1 w-[150px] rounded-[5px] text-center text-[12px] flex items-center justify-center">
                                    <MdVerified className="text-[#49B5FD]" /> Verified company
                                </h1>
                            )}
                        </div>

                        <div className="h-1/3 flex flex-col gap-3">
                            <div className="flex flex-row justify-between items-center w-full">
                                <p className="font-bold w-5/6 text-[22px] tracking-wide leading-5 break-words">{item.name}</p>
                                {
                                    item.profile_photo ?
                                        <img src={item.profile_photo} alt="" className="w-[50px] h-[50px] rounded-full" />
                                        : <img src={profileIcon} alt="" className="w-[50px] h-[50px] rounded-full" />
                                }
                            </div>
                        </div>

                        <div className="flex flex-col h-1/3   w-full">
                            <div className="w-1/2 flex flex-row items-start gap-2">
                                <MdLocationPin />
                                <h1>{item.country}</h1>
                            </div>
                            <div className=" w-full flex flex-row items-start gap-2">
                                <FaNetworkWired />
                                {/* <h1>{item.services}</h1> */}
                                <p className='text-gray-600'> {item.services && Array.isArray(item.services)
                                    ? item.services.join(', ')
                                    : item.services || 'No services available'}</p>
                            </div>
                        </div>



                        <div className="flex flex-row gap-2 justify-end border-t-[1px] border-[#E0E0E0] py-2 h-1/3 ">
                            <Button bgColor="w-[130px] h-[45px] border-[2px] border-[#49B5FD] rounded-[5px] text-[14px] text-[#49B5FD] font-medium">
                                Send Enquiry
                            </Button>
                        </div>
                    </div>
                ))}


            </div>

            {
                loading && (
                    <div className="w-full h-full flex items-center justify-center">
                        <Circles height="80" width="80" color="#142AA2" visible />
                        <h1 className="text-[#142AA2]">Loading ...</h1>
                    </div>
                )
            }

        </>
    )

}

export default Directory