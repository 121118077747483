import React, { useRef, useState, useEffect } from 'react'
import Button from '../components/Button';
import uploadLogoIcon from '../assets/uploadLogo.png'
import axios from 'axios';
import Select from 'react-select'
import makeAnimated from 'react-select/animated';
import { Circles } from 'react-loader-spinner';
import Dropdown from '../components/Dropdown';
const animatedComponents = makeAnimated();



const EditProfile = () => {
    const [errors, setErrors] = useState({});
    const [image, setImage] = useState('')
    const [profileImage, setProfileImage] = useState(null);
    const inputRef = useRef(null)
    const [companyName, setCompanyName] = useState('');
    const [servicesSector, setServicesSector] = useState([]);
    const [services, setServices] = useState([]);
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    // const [branchLocation, setBranchLocation] = useState('');
    const [socialMediaLinks, setSocialMediaLinks] = useState('');
    const [additionalNotes, setAdditionalNotes] = useState('');

    const [servicesSectorArray, setServicesSectorArray] = useState([]);
    const [servicesArray, setServicesArray] = useState([]);
    const [showOtherInput, setShowOtherInput] = useState(false);
    const [otherService, setOtherService] = useState('');
    const [showOtherServiceSectorInput, setShowOtherServiceSectorInput] = useState(false);
    const [otherServiceSector, setOtherServiceSector] = useState('');
    const [phoneError, setPhoneError] = useState('');
    const [additionalNotesError, setAdditionalNotesError] = useState('');
    const [loading, setLoading] = useState(false);
    const [country, setCountry] = useState('');
    const [countryCode, setCountryCode] = useState('');


    const Services = [
        // { label: "select", value: "select" },
        { label: "Air Freight", value: "Air Freight" },
        { label: "Sea Freight", value: "Sea Freight" },
        { label: "Custom Clearance", value: "Custom Clearance" },
        { label: "Transportation", value: "Transportation" },
        { label: "Other", value: "Other" },
    ]

    const ServiceSector = [
        // { label: "select", value: "select" },
        { label: "SeaPort", value: "Sea Port" },
        { label: "AirPort", value: "AirPort" },
        { label: "State", value: "State" },
        { label: "Other", value: "Other" },
    ]

    const Country = [
        { label: "Afghanistan", value: "Afghanistan" },
        { label: "Albania", value: "Albania" },
        { label: "Algeria", value: "Algeria" },
        { label: "Andorra", value: "Andorra" },
        { label: "Angola", value: "Angola" },
        { label: "Antigua and Barbuda", value: "Antigua and Barbuda" },
        { label: "Argentina", value: "Argentina" },
        { label: "Armenia", value: "Armenia" },
        { label: "Australia", value: "Australia" },
        { label: "Austria", value: "Austria" },
        { label: "Azerbaijan", value: "Azerbaijan" },
        { label: "Bahamas", value: "Bahamas" },
        { label: "Bahrain", value: "Bahrain" },
        { label: "Bangladesh", value: "Bangladesh" },
        { label: "Barbados", value: "Barbados" },
        { label: "Belarus", value: "Belarus" },
        { label: "Belgium", value: "Belgium" },
        { label: "Belize", value: "Belize" },
        { label: "Benin", value: "Benin" },
        { label: "Bhutan", value: "Bhutan" },
        { label: "Bolivia", value: "Bolivia" },
        { label: "Bosnia and Herzegovina", value: "Bosnia and Herzegovina" },
        { label: "Botswana", value: "Botswana" },
        { label: "Brazil", value: "Brazil" },
        { label: "Brunei", value: "Brunei" },
        { label: "Bulgaria", value: "Bulgaria" },
        { label: "Burkina Faso", value: "Burkina Faso" },
        { label: "Burundi", value: "Burundi" },
        { label: "Cabo Verde", value: "Cabo Verde" },
        { label: "Cambodia", value: "Cambodia" },
        { label: "Cameroon", value: "Cameroon" },
        { label: "Canada", value: "Canada" },
        { label: "Central African Republic", value: "Central African Republic" },
        { label: "Chad", value: "Chad" },
        { label: "Chile", value: "Chile" },
        { label: "China", value: "China" },
        { label: "Colombia", value: "Colombia" },
        { label: "Comoros", value: "Comoros" },
        { label: "Congo, Democratic Republic of the", value: "Congo, Democratic Republic of the" },
        { label: "Congo, Republic of the", value: "Congo, Republic of the" },
        { label: "Costa Rica", value: "Costa Rica" },
        { label: "Croatia", value: "Croatia" },
        { label: "Cuba", value: "Cuba" },
        { label: "Cyprus", value: "Cyprus" },
        { label: "Czech Republic", value: "Czech Republic" },
        { label: "Denmark", value: "Denmark" },
        { label: "Djibouti", value: "Djibouti" },
        { label: "Dominica", value: "Dominica" },
        { label: "Dominican Republic", value: "Dominican Republic" },
        { label: "Ecuador", value: "Ecuador" },
        { label: "Egypt", value: "Egypt" },
        { label: "El Salvador", value: "El Salvador" },
        { label: "Equatorial Guinea", value: "Equatorial Guinea" },
        { label: "Eritrea", value: "Eritrea" },
        { label: "Estonia", value: "Estonia" },
        { label: "Eswatini", value: "Eswatini" },
        { label: "Ethiopia", value: "Ethiopia" },
        { label: "Fiji", value: "Fiji" },
        { label: "Finland", value: "Finland" },
        { label: "France", value: "France" },
        { label: "Gabon", value: "Gabon" },
        { label: "Gambia", value: "Gambia" },
        { label: "Georgia", value: "Georgia" },
        { label: "Germany", value: "Germany" },
        { label: "Ghana", value: "Ghana" },
        { label: "Greece", value: "Greece" },
        { label: "Grenada", value: "Grenada" },
        { label: "Guatemala", value: "Guatemala" },
        { label: "Guinea", value: "Guinea" },
        { label: "Guinea-Bissau", value: "Guinea-Bissau" },
        { label: "Guyana", value: "Guyana" },
        { label: "Haiti", value: "Haiti" },
        { label: "Honduras", value: "Honduras" },
        { label: "Hungary", value: "Hungary" },
        { label: "Iceland", value: "Iceland" },
        { label: "India", value: "India" },
        { label: "Indonesia", value: "Indonesia" },
        { label: "Iran", value: "Iran" },
        { label: "Iraq", value: "Iraq" },
        { label: "Ireland", value: "Ireland" },
        { label: "Israel", value: "Israel" },
        { label: "Italy", value: "Italy" },
        { label: "Jamaica", value: "Jamaica" },
        { label: "Japan", value: "Japan" },
        { label: "Jordan", value: "Jordan" },
        { label: "Kazakhstan", value: "Kazakhstan" },
        { label: "Kenya", value: "Kenya" },
        { label: "Kiribati", value: "Kiribati" },
        { label: "Korea, North", value: "Korea, North" },
        { label: "Korea, South", value: "Korea, South" },
        { label: "Kosovo", value: "Kosovo" },
        { label: "Kuwait", value: "Kuwait" },
        { label: "Kyrgyzstan", value: "Kyrgyzstan" },
        { label: "Laos", value: "Laos" },
        { label: "Latvia", value: "Latvia" },
        { label: "Lebanon", value: "Lebanon" },
        { label: "Lesotho", value: "Lesotho" },
        { label: "Liberia", value: "Liberia" },
        { label: "Libya", value: "Libya" },
        { label: "Liechtenstein", value: "Liechtenstein" },
        { label: "Lithuania", value: "Lithuania" },
        { label: "Luxembourg", value: "Luxembourg" },
        { label: "Madagascar", value: "Madagascar" },
        { label: "Malawi", value: "Malawi" },
        { label: "Malaysia", value: "Malaysia" },
        { label: "Maldives", value: "Maldives" },
        { label: "Mali", value: "Mali" },
        { label: "Malta", value: "Malta" },
        { label: "Marshall Islands", value: "Marshall Islands" },
        { label: "Mauritania", value: "Mauritania" },
        { label: "Mauritius", value: "Mauritius" },
        { label: "Mexico", value: "Mexico" },
        { label: "Micronesia", value: "Micronesia" },
        { label: "Moldova", value: "Moldova" },
        { label: "Monaco", value: "Monaco" },
        { label: "Mongolia", value: "Mongolia" },
        { label: "Montenegro", value: "Montenegro" },
        { label: "Morocco", value: "Morocco" },
        { label: "Mozambique", value: "Mozambique" },
        { label: "Myanmar", value: "Myanmar" },
        { label: "Namibia", value: "Namibia" },
        { label: "Nauru", value: "Nauru" },
        { label: "Nepal", value: "Nepal" },
        { label: "Netherlands", value: "Netherlands" },
        { label: "New Zealand", value: "New Zealand" },
        { label: "Nicaragua", value: "Nicaragua" },
        { label: "Niger", value: "Niger" },
        { label: "Nigeria", value: "Nigeria" },
        { label: "North Macedonia", value: "North Macedonia" },
        { label: "Norway", value: "Norway" },
        { label: "Oman", value: "Oman" },
        { label: "Pakistan", value: "Pakistan" },
        { label: "Palau", value: "Palau" },
        { label: "Palestine", value: "Palestine" },
        { label: "Panama", value: "Panama" },
        { label: "Papua New Guinea", value: "Papua New Guinea" },
        { label: "Paraguay", value: "Paraguay" },
        { label: "Peru", value: "Peru" },
        { label: "Philippines", value: "Philippines" },
        { label: "Poland", value: "Poland" },
        { label: "Portugal", value: "Portugal" },
        { label: "Qatar", value: "Qatar" },
        { label: "Romania", value: "Romania" },
        { label: "Russia", value: "Russia" },
        { label: "Rwanda", value: "Rwanda" },
        { label: "Saint Kitts and Nevis", value: "Saint Kitts and Nevis" },
        { label: "Saint Lucia", value: "Saint Lucia" },
        { label: "Saint Vincent and the Grenadines", value: "Saint Vincent and the Grenadines" },
        { label: "Samoa", value: "Samoa" },
        { label: "San Marino", value: "San Marino" },
        { label: "Sao Tome and Principe", value: "Sao Tome and Principe" },
        { label: "Saudi Arabia", value: "Saudi Arabia" },
        { label: "Senegal", value: "Senegal" },
        { label: "Serbia", value: "Serbia" },
        { label: "Seychelles", value: "Seychelles" },
        { label: "Sierra Leone", value: "Sierra Leone" },
        { label: "Singapore", value: "Singapore" },
        { label: "Slovakia", value: "Slovakia" },
        { label: "Slovenia", value: "Slovenia" },
        { label: "Solomon Islands", value: "Solomon Islands" },
        { label: "Somalia", value: "Somalia" },
        { label: "South Africa", value: "South Africa" },
        { label: "South Sudan", value: "South Sudan" },
        { label: "Spain", value: "Spain" },
        { label: "Sri Lanka", value: "Sri Lanka" },
        { label: "Sudan", value: "Sudan" },
        { label: "Suriname", value: "Suriname" },
        { label: "Sweden", value: "Sweden" },
        { label: "Switzerland", value: "Switzerland" },
        { label: "Syria", value: "Syria" },
        { label: "Taiwan", value: "Taiwan" },
        { label: "Tajikistan", value: "Tajikistan" },
        { label: "Tanzania", value: "Tanzania" },
        { label: "Thailand", value: "Thailand" },
        { label: "Timor-Leste", value: "Timor-Leste" },
        { label: "Togo", value: "Togo" },
        { label: "Tonga", value: "Tonga" },
        { label: "Trinidad and Tobago", value: "Trinidad and Tobago" },
        { label: "Tunisia", value: "Tunisia" },
        { label: "Turkey", value: "Turkey" },
        { label: "Turkmenistan", value: "Turkmenistan" },
        { label: "Tuvalu", value: "Tuvalu" },
        { label: "Uganda", value: "Uganda" },
        { label: "Ukraine", value: "Ukraine" },
        { label: "United Arab Emirates", value: "United Arab Emirates" },
        { label: "United Kingdom", value: "United Kingdom" },
        { label: "United States", value: "United States" },
        { label: "Uruguay", value: "Uruguay" },
        { label: "Uzbekistan", value: "Uzbekistan" },
        { label: "Vanuatu", value: "Vanuatu" },
        { label: "Vatican City", value: "Vatican City" },
        { label: "Venezuela", value: "Venezuela" },
        { label: "Vietnam", value: "Vietnam" },
        { label: "Yemen", value: "Yemen" },
        { label: "Zambia", value: "Zambia" },
        { label: "Zimbabwe", value: "Zimbabwe" }
    ];

    const handleServiceSector = (option) => {
        
        const selectedServiceSectors = option.map(opt => opt.label);
        setServicesSectorArray(selectedServiceSectors);
        setServicesSector(option);

        // Show or hide the "Other" input field
        if (selectedServiceSectors.includes("Other")) {
            setShowOtherServiceSectorInput(true);
        } else {
            setShowOtherServiceSectorInput(false);
            setOtherServiceSector('');
        }
    }

    const handleOtherServiceSectorChange = (e) => {
        setOtherServiceSector(e.target.value);
    }



    const handleService = (option) => {
        
        const selectedServices = option.map(opt => opt.label);
        setServicesArray(selectedServices);
        setServices(option);

        // Show or hide the "Other" input field
        if (selectedServices.includes("Other")) {
            setShowOtherInput(true);
        } else {
            setShowOtherInput(false);
            setOtherService('');
        }
    }

    const handleOtherServiceChange = (e) => {
        setOtherService(e.target.value);
    }


    const handleImageClick = () => {
        inputRef.current.click()
    }
    const handleImage = (e) => {
        
        setImage(e.target.files[0])

    }


    const handleEditProfile = () => {
        if (phone.length !== 10) {
            setPhoneError("Invalid Phone No.");
            return;
        } else if (additionalNotes.length > 120) {
            setAdditionalNotesError('You can only input up to 120 characters.')
            return;
        } else {
            setLoading(true);
        }

        const filteredServices = servicesArray.filter(service => service !== "Other");
        const filteredServiceSector = servicesSectorArray.filter(service => service !== "Other");

        axios
            .put(
                "https://api.freightopedia.world/api/v1/accounts/profile",
                {
                    company_name: companyName,
                    about: additionalNotes,
                    phone: phone,
                    // services: Array.from(servicesArray),
                    services: showOtherInput ? [...filteredServices, otherService] : filteredServices,
                    // service_sector: Array.from(servicesSectorArray),
                    service_sector: showOtherServiceSectorInput ? [...filteredServiceSector, otherServiceSector] : filteredServiceSector,
                    social_media_links: socialMediaLinks,
                    country: country,
                    country_code: countryCode
                },
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("authToken")}`
                    },
                }
            )
            .then((res) => {
                setLoading(false);
                setPhoneError('');
                setAdditionalNotesError("");
                
                localStorage.setItem("userData", JSON.stringify(res.data.data));
                alert(res.data.message);


                // navigate("/dashboard")
            });

    }



    const submitProfilePicture = () => {
        setLoading(true);
        axios
            .post(
                "https://api.freightopedia.world/api/v1/accounts/profile",
                {
                    profile: image,
                },
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
                        "Content-Type": "multipart/form-data",
                    },
                }
            )
            .then((res) => {
                
                // localStorage.setItem("userData", JSON.stringify(res.data.data));

                if (res.data.success) {
                    getItems(true)
                }
                setLoading(false);
                alert(res.data.message);
            });
    }




    useEffect(() => {
        getItems();
    }, []);

    const getItems = (save_data = false) => {
        axios.get(`https://api.freightopedia.world/api/v1/accounts/profile`, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem("authToken")}`
            }
        })
            .then(response => {
                // handle success

                if (response.data.success) {
                    
                    
                    const data = response.data.data

                    if (save_data) {
                        localStorage.setItem('userData', JSON.stringify(data))
                    } else {
                        setCompanyName(data.company_name)
                        setAdditionalNotes(data.about)
                        setPhone(data.phone)
                        setEmail(data.email)
                        setServices(data.services)
                        setServicesSector(data.service_sector)
                        setSocialMediaLinks(data.social_media_links)
                        setProfileImage(data.profile_photo)
                        setCountryCode(data.country_code)
                        setCountry(data.country)
                    }
                }


            })
            .catch(error => {
                // handle error
                
            });

    }


    const numberInputOnWheelPreventChange = (e) => {
        // Prevent the input value change
        e.target.blur()

        // Prevent the page/container scrolling
        e.stopPropagation()

        // Refocus immediately, on the next tick (after the current function is done)
        setTimeout(() => {
            e.target.focus()
        }, 0)
    }


    const handlePortOfLoading = (option) => {
        setCountry(option.label)
    };

    return (
        <>
            <div className='flex flex-col gap-5 items-center justify-center px-10 '>
                <div className="">
                    <div
                        onClick={handleImageClick}
                        className="flex flex-col items-center justify-center cursor-pointer">

                        {/* {image ? <img src={URL.createObjectURL(image)} alt="" className='rounded-full w-[100px] h-[100px] border-[1px] border-[#246BFD]' /> : <img src={profileImage} alt="" className='rounded-full w-[100px] h-[100px] border-[1px] border-[#246BFD]' />} */}
                        {/* {image ? (
                        <img
                            src={URL.createObjectURL(image)}
                            alt=""
                            className='rounded-full w-[100px] h-[100px] border-[1px] border-[#246BFD]'
                        />
                    ) : (
                        <img
                            src={profileImage || uploadLogoIcon}
                            alt=""
                            className='rounded-full w-[100px] h-[100px] border-[1px] border-[#246BFD]'
                        />
                    )} */}

                        <div className="relative w-[100px] h-[100px] border-[1px] border-[#246BFD] rounded-full overflow-hidden flex items-center justify-center text-white text-[10px] font-bold">
                            {!image && !profileImage ? (
                                <>
                                    <div className="absolute bg-img inset-0 bg-custom-background bg-cover bg-no-repeat"></div>
                                    <div className="absolute inset-0 bg-gray-500 bg-opacity-45"></div>
                                    <h1 className="relative z-10 text-center">Upload your <br /> logo</h1>
                                </>
                            ) : (
                                <img
                                    src={image ? URL.createObjectURL(image) : profileImage}
                                    alt=""
                                    className='absolute inset-0 w-full h-full object-cover rounded-full'
                                />
                            )}
                        </div>


                        <input type="file" name='file' ref={inputRef} onChange={handleImage} className='cursor-pointer' style={{ display: 'none' }} />
                    </div>
                    <Button onClick={submitProfilePicture} bgColor="mt-4 py-2  px-5 w-fit rounded-[5px] text-[11px] text-white font-medium bg-gradient-to-r from-btnPrimary to-btnSecondary flex flex-row gap-2 items-center justify-center " >Save Profile Photo</Button>
                </div>

                <div className="w-full  flex flex-row justify-between gap-10 ">
                    <div className="w-1/2 flex flex-col">
                        <div className=" flex flex-col ">
                            <label htmlFor="" className='text-[13px] leading-[35px]' >Company Name</label>
                            <input type="text"
                                value={companyName}
                                onChange={(e) => setCompanyName(e.target.value)}
                                className='border-[#DDDDDD] text-[13px] py-2 outline-none border-[1px] bg-[#F6F6F6] px-[10px] w-full ' />
                        </div>

                        <div className="">
                            <label htmlFor="" className='text-[13px] leading-[35px]' >Services sector</label>
                            {/* <Dropdown
                            value={servicesSector}
                            onChange={(option) => setServicesSector(option.label)}
                            className='outline-none text-[13px] bg-[#F6F6F6] border-[1px] border-[#DDDDDD] py-2 px-2 w-full '
                            options={ServiceSector} /> */}

                            <Select
                                options={ServiceSector}
                                components={animatedComponents}
                                // value={servicesSector}
                                isMulti={true}
                                isClearable={false}
                                closeMenuOnSelect={false}
                                onChange={handleServiceSector}
                                placeholder="Select services sector..."
                            />
                            {showOtherServiceSectorInput && (
                                <input
                                    type="text"
                                    value={otherServiceSector}
                                    onChange={handleOtherServiceSectorChange}
                                    placeholder="Enter other service sector"
                                    className="border-[#DDDDDD] text-[13px] py-3 outline-none border-[1px] bg-[#F6F6F6] px-[10px] mt-2 w-full"
                                />
                            )}

                        </div>


                        <div className="w-full">
                            <label htmlFor="" className='text-[13px] leading-[35px] ' >Country</label>
                            <Dropdown
                                value={country}
                                className='outline-none text-[13px] bg-[#F6F6F6] border-[1px] border-[#DDDDDD] py-2 px-2 w-full  ' options={Country}
                                onChange={handlePortOfLoading}
                            />
                        </div>



                        <div className="flex flex-col  ">
                            <label htmlFor="additional-notes" className='text-[13px] leading-[35px] text-[#525252]   '>About</label>
                            <textarea
                                id="additional-notes"
                                type="text" rows="5"
                                value={additionalNotes}
                                onChange={(e) => setAdditionalNotes(e.target.value)}
                                className=' border-[#DDDDDD] resize-none  text-[13px] py-2 outline-none border-[1px] bg-[#F6F6F6] px-[10px] '
                            />
                            {
                                additionalNotesError && <div className="text-red-500 text-[14px] ">{additionalNotesError}</div>
                            }
                        </div>
                    </div>


                    {/* 2nd half */}
                    <div className="w-1/2 flex flex-col  ">

                        <div className="">
                            <label htmlFor="" className='text-[13px] leading-[35px]' >Services</label>
                            {/* <Dropdown
                            value={services}
                            onChange={(option) => setServices(option.label)}
                            className='outline-none text-[13px] bg-[#F6F6F6] border-[1px] border-[#DDDDDD] py-2 px-2 w-full ' options={Services} /> */}

                            <Select
                                options={Services}
                                components={animatedComponents}
                                // value={services}
                                isMulti={true}
                                isClearable={false}
                                closeMenuOnSelect={false}
                                onChange={handleService}
                                placeholder="Select services..."
                            />
                            {showOtherInput && (
                                <input
                                    type="text"
                                    placeholder="Please specify other service"
                                    value={otherService}
                                    onChange={handleOtherServiceChange}
                                    className="border-[#DDDDDD] text-[13px] py-3 outline-none border-[1px] bg-[#F6F6F6] px-[10px] mt-2 w-full"
                                />
                            )}

                        </div>
                        {loading &&
                            <div className="fixed inset-0 bg-black bg-opacity-30 backdrop-brightness-100  flex items-center justify-center">
                                <div className="w-fit flex flex-col items-center justify-center gap-3 ">
                                    <Circles
                                        height="80"
                                        width="80"
                                        color="#142AA2"
                                        ariaLabel="circles-loading"
                                        wrapperStyle={{}}
                                        wrapperClass=""
                                        visible={true}
                                    />


                                    <h1 className='text-[#142AA2]' >Loading</h1>


                                </div>
                            </div>
                        }
                        <div className="  flex flex-col ">
                            <label htmlFor="" className='text-[13px] leading-[35px]' >Your Email</label>
                            <input type="email"
                                disabled
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                className='border-[#DDDDDD] text-[13px] py-2 outline-none border-[1px] bg-[#F6F6F6] px-[10px] w-full ' />
                        </div>
                        <div className=" flex flex-col ">
                            <label htmlFor="" className='text-[13px] leading-[35px]' >Your Phone no.</label>
                            <div className="border-[#DDDDDD] text-[13px] py-2  border-[1px] bg-[#F6F6F6] w-full">
                                <input type="text"
                                    placeholder='+91'
                                    value={countryCode}
                                    onChange={(e) => setCountryCode(e.target.value)}
                                    className='border-[#DDDDDD] text-[13px] py-1 px-2 border-[1px] bg-[#F6F6F6] w-[100px] ml-3 outline-none ' />
                                <input type="number" onWheel={numberInputOnWheelPreventChange}
                                    value={phone}
                                    onChange={(e) => setPhone(e.target.value)}
                                    className=' outline-none bg-[#F6F6F6] ml-3' />
                            </div>
                            {
                                phoneError && <div className="text-red-500 text-[14px] ">{phoneError}</div>
                            }
                        </div>

                        <div className=" flex flex-col ">
                            <label htmlFor="" className='text-[13px] leading-[35px]' >Social Media Links</label>
                            <input type="text"
                                value={socialMediaLinks}
                                onChange={(e) => setSocialMediaLinks(e.target.value)}
                                className='border-[#DDDDDD] text-[13px] py-2 outline-none border-[1px] bg-[#F6F6F6] px-[10px] w-full' />
                        </div>


                    </div>
                </div>

                <div className="">
                    <Button onClick={handleEditProfile} bgColor="mt-4 w-[210px] h-[45px] rounded-[5px] text-[16px] text-white font-medium bg-gradient-to-r from-btnPrimary to-btnSecondary flex flex-row gap-2 items-center justify-center " >Save</Button>
                </div>
            </div>
        </>
    )
}

export default EditProfile