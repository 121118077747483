import React, { useState, useEffect, useContext } from 'react'
import Sidebar from '../components/Sidebar'
import { Outlet } from 'react-router-dom'
import Button from '../components/Button';
import { BsPlusLg } from "react-icons/bs";
import indiaIcon from '../assets/indiaIcon.png';
import usaIcon from '../assets/usaIcon.png';
import { CgArrowLongRight } from "react-icons/cg";
import Dropdown from '../components/Dropdown';
import { IoMdClose } from "react-icons/io";
import { Link, useNavigate } from 'react-router-dom';
import Modal from '../components/Modal'
import axios from 'axios';

import ModalQuotationDetails from '../components/ModalQuotationDetails';
import Nav from '../components/Nav';
import SearchBar from '../components/SearchBar';
import { ThreeCircles } from 'react-loader-spinner';
import SearchContext from '../SearchContext';


const InquiryHub = () => {

    const [newQuote, setNewQuote] = useState(false);
    const [freightMedium, setFreightMedium] = useState('');
    const [freightType, setFreightType] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [activeQuotationRequest, setActiveQuotationRequest] = useState('');
    const [inquiryHubData, setInquiryHubData] = useState([]);
    const [selectedFreight, setSelectedFreight] = useState('');
    const [isDropdownOpen, setIsDropdownOpen] = useState(true);
    const [loading, setLoading] = useState(false);
    const [filteredData, setFilteredData] = useState([]);
    const { searchQuery } = useContext(SearchContext);
    const [data, setData] = useState([]);


    const userData = JSON.parse(localStorage.getItem('userData'))

    const funNewQuote = () => {
        setNewQuote(!newQuote);
    }
    const navigate = useNavigate();


    const handleViewDetails = (qr_id) => {
        
        setActiveQuotationRequest(qr_id)
        setIsModalOpen(true)
    }


    const freightOptions = [
        'Ocean Freight (FCL)',
        'Ocean Freight (LCL)',
        'Air Freight'
    ];
    const handleSelect = (option) => {
        setSelectedFreight(option);

        
        if (option === "Ocean Freight (FCL)") {
            navigate("/create-quote-fcl")

        } else if (option === "Ocean Freight (LCL)") {
            navigate("/create-quote-lcl")

        } else if (option === "Air Freight") {
            navigate("/create-quote-air")

        }



        setIsDropdownOpen(false); // Close dropdown after selection
        
        
    }
    const funHandlefreightMediumAndType = () => {
        if (selectedFreight === 'Ocean Freight (FCL)') {
            setFreightMedium('Ocean');
            setFreightType('FCL');
        } else if (selectedFreight === 'Ocean Freight (LCL)') {
            setFreightMedium('Ocean');
            setFreightType('LCL');
        } else if (selectedFreight === 'Air Freight') {
            setFreightMedium('Air');
        }

    }


    const handleToggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const [isComponentVisible, setIsComponentVisible] = useState(true);
    const handleClose = () => {
        // setSelectedFreight('');
        // setIsDropdownOpen(false); // Ensure dropdown is closed when reset
        setNewQuote(!newQuote); // Close the entire component
    };


    const [quotatioRequestData, setQuotatioRequestData] = useState([]);


    const getData = () => {
        axios.get(`https://api.freightopedia.world/api/v1/logistics/inquiry-hub?user_id=${userData !== null ? userData.id : ""}`, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem("authToken")}`
            }
        })
            .then(response => {
                setLoading(false);
                // handle success
                
                

                if (response.data.success) {

                    setInquiryHubData(response.data.data)
                    

                }
            })
            .catch(error => {
                // handle error
                
            });
    }

    useEffect(() => {
        setLoading(true);
        getData();
        fetchData();
    }, [])


    useEffect(() => {
        if (searchQuery) {
            const lowercasedQuery = searchQuery.toLowerCase();
            const filtered = data.filter(item =>
                Object.values(item).some(
                    value =>
                        value && typeof value === 'string' && value.toLowerCase().includes(lowercasedQuery)
                )
            );
            setFilteredData(filtered);
        } else {
            setFilteredData([]); // Clear filtered data if searchQuery is empty
        }
    }, [searchQuery, data]);


    const fetchData = () => {
        axios.get(`https://api.freightopedia.world/api/v1/logistics/inquiry-hub?user_id=${userData !== null ? userData.id : ""}`, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem("authToken")}`
            }
        })
            .then(response => {
                setLoading(false);
                // handle success
                
                

                if (response.data.success) {
                    if (response.data.success) {
                        setData(response.data.data);
                        // setFilteredData(response.data.data);
                    }
                }
            })
            .catch(error => {
                // handle error
                
            });
    };

    return (
        <div>
            <Outlet />
            <div className="flex flex-col justify-center items-center  py-[40px] px-[20px] xs:gap-[90px] sm:gap-[50px]">
                <Nav />
                <SearchBar />
                <div className="w-full">
                    <div className='grid max-md:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-y-8 gap-x-3 mt-3'>

                        {
                            isModalOpen && <ModalQuotationDetails quotationRequest={activeQuotationRequest} isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} user={"transporter"} showButton={true} />
                        }



                        {filteredData.length === 0 && searchQuery ? (
                            <p>No results found</p>
                        ) : ((searchQuery ? filteredData : inquiryHubData).map((item, index) => (

                            <div key={index} className="border-[1px] border-[#E0E0E0] px-5 md:px-2">
                                <div className=" flex flex-col gap-3  pt-5 pb-1 md:pr-10 md:pl-2 lg:px-5 w-auto max-w-[100%]">
                                    <h1 className='text-[#6F25EB] border-[1px] border-[#6F25EB] py-1 w-fit px-5 rounded-[5px] text-center bg-[#6f25eb14] flex items-center justify-center'  >{item.freight_medium} Freight</h1>
                                    <h1>Commodity</h1>
                                    <p className='font-bold'>{item.commodity}</p>
                                    <div className="flex flex-row gap-5 items-center">
                                        <div className="">
                                            <label className="text-[13px] text-[#595959]" >Cargo wt</label>
                                            <h1 className='font-bold'>{item.cargo_weight}</h1>
                                        </div>

                                        <div className="w-1/2">
                                            <label className="text-[13px] text-[#595959]">Quote no. </label>
                                            <h1 className='font-bold'>{item.quote_no}</h1>
                                        </div>


                                    </div>
                                    <div className="flex flex-row items-center gap-5">
                                        <div className="flex flex-row items-center gap-2">
                                            {/* <img src={usaIcon} alt="" className='w-[35px] h-[20px]' /> */}
                                            <h1 className='font-bold'>{item.source}</h1>
                                        </div>
                                        <CgArrowLongRight className='text-[35px]' />
                                        <div className="flex flex-row items-center gap-2">
                                            {/*<img src={indiaIcon} alt="" className='w-[35px] h-[20px]' />*/}
                                            <h1 className='font-bold'>{item.destination} </h1>
                                        </div>
                                    </div>

                                    <div className="flex flex-row gap-2 justify-end border-t-[1px] border-[#E0E0E0] py-2">

                                        <Button onClick={() => handleViewDetails(item.id)} bgColor="w-[130px] h-[45px] rounded-[5px] text-[14px] text-white font-medium bg-[#49B5FD]">View Details</Button>
                                    </div>


                                </div>
                            </div>
                        ))
                        )
                        }


                    </div>


                </div>

                {loading &&
                    <div className=" h-full w-full bg-opacity-30 backdrop-brightness-100 flex items-center justify-center">
                        <div className="w-fit flex flex-col items-center justify-center gap-3">
                            <ThreeCircles
                                visible={true}
                                height="100"
                                width="100"
                                color="#142AA2"
                                ariaLabel="three-circles-loading"
                                wrapperStyle={{}}
                                wrapperClass=""
                            />
                            <h1 className='text-[#142AA2]'>Loading</h1>
                        </div>
                    </div>
                }



            </div>
        </div>
    )
}

export default InquiryHub