import React, { useState, useEffect, useRef } from 'react'
import Button from './Button'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { BsDot } from "react-icons/bs";
import { IoIosBoat } from "react-icons/io";
import { motion, AnimatePresence, useAnimation } from 'framer-motion';
import nikeIcon from '../assets/nike.png'
import { MdKeyboardDoubleArrowRight } from "react-icons/md";
import axios from 'axios';
import { Circles } from 'react-loader-spinner';
import { RotatingLines } from 'react-loader-spinner';
import uploadLogo from '../assets/uploadLogo.png'




const Component1 = () => <div style={{ backgroundCsolor: 'lightblue' }}>
    {/* <p className='text-[15px]' >Nike, Inc. is a multinational corporation specializing in the design, manufacturing, and marketing of athletic apparel, footwear, accessories, and equipment.</p> */}
    <div className="flex flex-row">
        {/* <img src={nike} alt="" className='w-[50px]' /> */}
        {/* <div className="">
            <h1 className='leading-[20px]'>Nike</h1>
            <p className='text-[13px]'>Beaverton, Oregon</p>
        </div> */}
    </div>
</div>
const Component2 = () => <div style={{ backgroundColor: 'lightgreen' }}>Component 2</div>;
const Component3 = () => <div style={{ backgroundColor: 'lightcoral' }}>Component 3</div>;
const Component4 = () => <div style={{ backgroundColor: 'lightcoral' }}>Component 4</div>;
const Component5 = () => <div style={{ backgroundColor: 'lightcoral' }}>Component 5</div>;
const Component6 = () => <div style={{ backgroundColor: 'lightcoral' }}>Component 6</div>;
const components = [Component1, Component2, Component3, Component4, Component5, Component6];

const NewMembers = () => {

    const [newMembersData, setNewMembersData] = useState([]);
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 768);
    // const [index, setIndex] = useState(0);
    const [isExpanded, setIsExpanded] = useState(false);
    const [index, setIndex] = useState(0);  // State to track the current slide index
    const [expandedIndex, setExpandedIndex] = useState(null);  // State to track the expanded slide
    const [loading, setLoading] = useState(false);


    const handleResize = () => {
        setIsSmallScreen(window.innerWidth < 768);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const slidesToShow = isSmallScreen ? 1 : 3;

    const controls = useAnimation();
    const intervalRef = useRef();

    const updateIndex = () => {
        setIndex((prevIndex) => (prevIndex + 1) % components.length);
    };

    useEffect(() => {
        intervalRef.current = setInterval(updateIndex, 3000);
        return () => clearInterval(intervalRef.current);
    }, []);

    useEffect(() => {
        controls.start({
            x: [0, -100, 0],
            transition: { duration: 2, }
        });
    }, [index, controls]);





    var settings = {
        className: "center",
        centerMode: true,
        centerPadding: "60px",
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 0,
        autoplay: true,
        autoplaySpeed: 1500, // Adjust the speed as needed
        afterChange: current => setIndex(current),  // Set the index after each slide change,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 1023,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    // initialSlide: 0
                }
            },
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    // initialSlide: 0
                }
            },
            {
                breakpoint: 700,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    // initialSlide: 0
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,

                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 375,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    const getData = () => {
        axios.get(`https://api.freightopedia.world/api/v1/logistics/newly-joined-members`, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem("authToken")}`
            }
        })
            .then(response => {
                setLoading(false);
                // handle success




                if (response.data.success) {

                    setNewMembersData(response.data.data)



                }
            })
            .catch(error => {
                // handle error

            });
    }

    useEffect(() => {
        setLoading(true);
        getData();
    }, [])


    const toggleExpansion = (ind) => {
        if (expandedIndex === ind) {
            setExpandedIndex(null);  // Collapse if already expanded
        } else {
            setExpandedIndex(ind);  // Expand the clicked slide
        }
    };

    return (
        <>
            <div className="w-screen py-5 container px-2 relative flex flex-col items-center justify-center ">
                <div className="flex flex-col items-center justify-center py-3 w-screen ">
                    <h1 className=' text-[22px] sm:text-[35px] font-medium text-[#000000] '>Newly Join  <span className='text-[#49B5FD] font-bold'>Member</span></h1>
                </div>

                {loading &&
                    <div className="w-full h-full  bg-opacity-30 backdrop-brightness-100 flex items-center justify-center">
                        <div className="w-fit pt-5 ">
                            <RotatingLines
                                visible={true}
                                height="50"
                                width="50"
                                // color="#142AA2"
                                strokeColor="#20586a"
                                strokeWidth="5"
                                animationDuration="0.75"
                                ariaLabel="rotating-lines-loading"
                                wrapperStyle={{}}
                                wrapperClass=""
                            />
                        </div>
                    </div>
                }


                <div className="w-screen overflow-hidden flex justify-center px-5" >
                    <Slider {...settings} className='w-full overflow-hidden '>

                        {
                            newMembersData.map((data, ind) => {
                                return (
                                    <div key={ind} className='flex flex-col justify-between h-[250px]  p-2  border-[#E0E0E0] border-[1px] break-words ' >
                                        <div className="h-1/3 flex flex-row  items-start justify-start gap-2 break-words">
                                            <div className="w-[50px] h-[50px] rounded-full ">
                                                {/* <img src={uploadLogo} alt="" className='w-full h-full rounded-full' /> */}
                                                <img src={data.profile_photo ? data.profile_photo : uploadLogo} alt="" className='w-full h-full rounded-full' />
                                            </div>
                                            <div className="">
                                                <h1 className='text-[22px]f font-bold'>{data.company_name}</h1>
                                            </div>
                                        </div>
                                        {/* onClick={() => setIsExpanded(!isExpanded)} */}
                                        <div className=" flex flex-row justify-between gap-3 mt-2  w-full break-words  ">
                                            <div className="w-1/2">
                                                <h1 className='flex flex-col' > <span className='font-bold' > Service Sector</span> {data.service_sector}</h1>
                                            </div>

                                            <div className="w-1/2 flex justify-end items-start">
                                                <h1 className='flex flex-col justify-end' > <span className='font-bold flex justify-end'>Services</span> {data.services}</h1>
                                            </div>
                                        </div>
                                        <div className='cursor-pointer mt-2 break-words '>
                                            <div className={`overflow-hidden text-[15px]  text-[#3B3B3B] flex flex-col   ${isExpanded ? 'whitespace-normal' : 'truncate'}`}>
                                                <h1 className='font-bold' >About</h1>
                                                <h1 className='whitespace-normal'>{data.about}</h1>

                                            </div>
                                        </div>

                                    </div>
                                )
                            })
                        }

                    </Slider>
                </div>

                <div className="dots flex justify-center p-2">
                    {newMembersData.map((_, i) => (
                        <span
                            key={i}
                            className={`dot ${index === i ? 'active' : ''}`}
                            onClick={() => setIndex(i)}  // Allows clicking on dots to change slides
                        />
                    ))}
                </div>
            </div>
        </>

    )
}
export default NewMembers


